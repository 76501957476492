import { createGlobalStyle } from 'styled-components';
import bg from '../images/digital-brain-background.svg';

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
      font-size: 16px;
    }
    
    body {
      background-image: url(${bg});
      background-position: 110%;
      background-size: 40%;
      background-repeat: repeat-y;
      background-color: #0F0F1D;
      font-family: ${(props) => props.theme.fonts.main};
      font-weight: 400;
    }

    ::selection {
      background: ${(props) => props.theme.colors.green1};
    }

    .button {
      box-sizing: border-box;
      margin: 0 0 1rem 0;
      background-color: ${(props) => props.theme.colors.black2};
      color: ${(props) => props.theme.colors.white2};
      font-family: ${(props) => props.theme.fonts.main};
      font-size: 1rem;
      border: none;
      padding: 0.5rem 1rem;
    }

    .primary-button {
      padding: 0.5rem 1.5rem;
      margin: 0 0 1rem 0;
      color: ${(props) => props.theme.colors.white3};
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: normal;
      background-color: ${(props) => props.theme.colors.green1};
      border: none;
      border-radius: 1.25rem;
      box-shadow: ${(props) => props.theme.shadows.glowHoverGreenStart};
      transition: ${(props) => props.theme.animations.buttonGlow};
      cursor: pointer;
      text-decoration: none;
    }

    .primary-button:hover,
    .primary-button:focus {
      box-shadow: ${(props) => props.theme.shadows.glowHoverGreenEnd};
    }

    .text-left {
      text-align: left;
    }

    .text-white {
      color: #fff;
    }

    h1 {
      font-weight: 600;
      font-size: 4rem;
      line-height: 5.25rem;
      color: #fff;
      letter-spacing: 0.5rem;
    }

    h2 {
      color: #fff;
      margin: 2rem auto;
    }

    p {
      font-size: 1rem;
      color: #fff;
    }

    .notice a {
      font-size: 0.75rem;
    }


  .wide-container {
    grid-column: 4 / span 8;
  }

    @media ${(props) => props.theme.breakpoints.m} {
    :root {
      font-size: 14px;
    }
  }

`;
