import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <FooterContainer>
        <Link to='/'>© 2021 Digital Brain®</Link>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
    grid-column: 2 / span 12;
    text-align: center;

  img {
    width: 10rem;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  a:first-child {
    margin-right: auto;
  }

  a,
  button {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.white3};
    text-decoration: none;
    margin-left: 2rem;
    transition: ${(props) => props.theme.animations.link};
  }

  a:hover,
  a:focus,
  button:hover,
  button:focus {
    color: ${(props) => props.theme.colors.green1};
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
  }
`;

export default Footer;
