import React from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import Text from '../Typography/Text';

import Logo from '../../images/digital-brain-logo.svg';

const NotFound = () => {
  return (
    <NotFoundContainer>
      <img src={Logo} alt='Logo' style={{width: "300px"}} />
      <br /><br /><br /><br />
      <h1>ERROR 404</h1>
      <Text>We couldn’t find the page you were looking for.</Text>
      <br /><br /><br /><br />
      <NavLink className={"button primary-button"} to="/">Go Back</NavLink>
    </NotFoundContainer>
  );
};

const NotFoundContainer = styled.div`
  grid-column: 2 / span 12;
  padding: 3rem 0 5.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
  }
`;

export default NotFound;
