import React from 'react';
import styled from 'styled-components';

import { useAuth } from '../../hooks/useAuth';

const Nav = () => {
  const auth = useAuth();

  return (
    <NavContainer>
      <span></span>
      {auth.user && <button onClick={() => auth.logout()}>Logout</button>}
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  grid-column: 2 / span 12;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10rem;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  a:first-child {
    margin-right: auto;
  }

  a,
  button {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.white3};
    text-decoration: none;
    margin-left: 2rem;
    transition: ${(props) => props.theme.animations.link};
  }

  a:hover,
  a:focus,
  button:hover,
  button:focus {
    color: ${(props) => props.theme.colors.green1};
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
  }
`;

export default Nav;
